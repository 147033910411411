import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agbdialog',
  templateUrl: './agbdialog.component.html'
})
export class AGBDialogComponent {
  constructor(public dialogRef: MatDialogRef<AGBDialogComponent>) { }

  onOKClick(): void {
    this.dialogRef.close();
  }

}
