import { AbstractControl, ValidationErrors } from '@angular/forms';


export class ValidateCheckbox {
  static validate(control: AbstractControl): ValidationErrors | null {
    const isOpt1 = control.get("opt1")?.value;
    const isOpt2 = control.get("opt2")?.value;
    const isOpt3 = control.get("opt3")?.value;
    const isOpt4 = control.get("opt4")?.value;

    if(isOpt1 || isOpt2 || isOpt3 || isOpt4) {
      return null;
    } 

    return ({required_at_least: true});
  }
}