import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Post } from 'src/shared/Post';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(private db: AngularFirestore) { }

  getPosts(limitBy?: number) {
    return limitBy 
    ? this.db.collection<Post>('approved-posts', ref => ref.orderBy('published', 'desc').limit(limitBy)).valueChanges() 
    : this.db.collection<Post>('approved-posts', ref => ref.orderBy('published', 'desc')).valueChanges();
  }

  getApprovedPost(slug: string) {
    return this.db.collection<Post>('approved-posts', ref => ref.where('slug', '==', slug)).valueChanges(
      { idField: 'id' }
    );
  }

  // FOR ADMINS (DASHBOARD)
  getPublishedPosts(): Observable<Post[]> {
    return this.db.collection<Post>('approved-posts', ref => ref
      .orderBy('published', 'desc')
    ).valueChanges();
  }

  // TODO: imp createDraft Post
  async createDraft(post: Post) { }

  async createPost(post: Post): Promise<firebase.default.firestore.DocumentReference> {
    return this.db.collection('approved-posts').add(post);
  }

  updatePost(post: Post): Promise<void> {
    return this.db.doc<Post>(`approved-posts/${post.id}`).update(post);
  }

  deleteApprovedPost(postId: string): Promise<void> {
    return this.db
      .collection('approved-posts')
      .doc(postId)
      .delete()
  }

  slugify(string): string {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}
