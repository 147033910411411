import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';

import { AuthService } from './services/auth.service';
import { CookieConsentService } from './services/cookie-consent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService, 
    private ccService: NgcCookieConsentService,
    private cookieConsentService: CookieConsentService) { }

  authSubs: Subscription[];
  private statusChangeSubscription: Subscription;


  ngOnInit(): void {
    this.authSubs = this.authService.initAuthListener();

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        switch(event.status) {
          case 'allow': {
            this.cookieConsentService.cookieChange.next(true);
            break;
          }
          case 'deny': {
            this.cookieConsentService.cookieChange.next(false);
            break;
          }
          case 'dismiss': {
            this.cookieConsentService.cookieChange.next(false);
            break;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.authSubs.forEach( sub => sub.unsubscribe());
    this.statusChangeSubscription.unsubscribe();
  }

}
