import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';

import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { PostComponent } from './pages/post/post.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { VereinComponent } from './pages/verein/verein.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { AllnewsComponent } from './pages/allnews/allnews.component';
import { MemberComponent } from './member/member.component';
import { EditProfileComponent } from './user/edit-profile/edit-profile.component';

import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavListComponent } from './components/header/sidenav-list/sidenav-list.component';
import { EscapeHTMLPipe } from '../shared/escape-html.pipe';
import { AGBDialogComponent } from './components/agbdialog/agbdialog.component';
import { NewsCardComponent } from './components/news-card/news-card.component';

import { 
  RecaptchaLoaderService, 
  RecaptchaModule, 
  RecaptchaFormsModule, 
  RECAPTCHA_SETTINGS, 
  RecaptchaSettings, 
  RecaptchaV3Module, 
  RECAPTCHA_V3_SITE_KEY  
} from 'ng-recaptcha';

import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NutzungsbedingungenComponent } from './pages/nutzungsbedingungen/nutzungsbedingungen.component';
import { DatenschutzDialogComponent } from './components/datenschutz-dialog/datenschutz-dialog.component';

import { PreloadedRecaptchaAPIService } from './services/preloadedRecaptchaAPI.service';

import { NgcCookieConsentModule} from 'ngx-cookieconsent';
import { cookieConfig } from './CookieConsentConfig';
import { TeaserBoxComponent } from './components/teaser-box/teaser-box.component';
import { SurveyMunichComponent } from './components/survey-munich/survey-munich.component';

export const service = new PreloadedRecaptchaAPIService();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomeComponent,
    FooterComponent,
    VereinComponent,
    FaqComponent,
    ContactComponent,
    DatenschutzComponent,
    ImpressumComponent,
    PostComponent,
    MemberComponent,
    EditProfileComponent,
    SidenavListComponent,
    EscapeHTMLPipe,
    AGBDialogComponent,
    NewsCardComponent,
    AllnewsComponent,
    ProfileCardComponent,
    NutzungsbedingungenComponent,
    DatenschutzDialogComponent,
    TeaserBoxComponent,
    SurveyMunichComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },  
    { provide: RecaptchaLoaderService, useValue: service },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Lc5G8gZAAAAAFuObtSHvY-xCkjb-fzXlZM6sbe0' } as RecaptchaSettings,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lc4fcwUAAAAAJjlJoWsvuzBOVwuE0yB8EA3kibm' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AGBDialogComponent]
})
export class AppModule { }