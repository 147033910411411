import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from 'src/shared/Profile';
import { SnackService } from 'src/shared/snack.service';
import { MatDialog } from '@angular/material/dialog';
import { AGBDialogComponent } from 'src/app/components/agbdialog/agbdialog.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  form: FormGroup;
  serverMessage: string;
  loading: false;

  constructor(private profileService: ProfileService, 
    private fb: FormBuilder, 
    private snackService: SnackService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    this.form = this.fb.group({
      // TODO: fix nickname validator _ pattern is not recognized as invalid!
      nickname:     ['', [Validators.required, Validators.minLength(1), Validators.pattern(/[a-zA-Z0-9-]*/)]],
      firstname:    ['', [Validators.required, Validators.minLength(1)]],
      lastname:     ['', [Validators.required, Validators.minLength(1)]],
      zip:          ['', [Validators.required, Validators.minLength(1)]],
      city:         ['', [Validators.required, Validators.minLength(1)]],
      street:       ['', [Validators.required, Validators.minLength(1)]],
      housenumber:  ['', [Validators.required, Validators.minLength(1)]],
      country:      ['', [Validators.required, Validators.minLength(1)]],
      dateofbirth:  ['', [Validators.required, Validators.minLength(1)]],
      email:        ['', [Validators.required, Validators.email]],
      readAGB:      [false, [Validators.requiredTrue]]
    });
  }

  get nickname()    { return this.form.get('nickname');     }
  get firstname()   { return this.form.get('firstname');    }
  get lastname()    { return this.form.get('lastname');     }  
  get zip()         { return this.form.get('zip');          }
  get city()        { return this.form.get('city');         }
  get street()      { return this.form.get('street');       }
  get housenumber() { return this.form.get('housenumber');  }
  get country()     { return this.form.get('country');      }
  get dateofbirth() { return this.form.get('dateofbirth');  }
  get email()       { return this.form.get('email');        }
  
  onSubmit() {
    var profile: Profile = {
      nickname: this.nickname.value,
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      zip: this.zip.value,
      city: this.city.value,
      street: this.street.value,
      housenumber: this.housenumber.value,
      country: this.country.value,
      dateofbirth: this.dateofbirth.value,
      email: this.email.value,
      role: "member"
    };

    this.profileService.updateProfile(profile).then( () => {
      this.snackService.showMessage('Profil erfolgreich aktualisiert!');
    }).catch( (err) => {
      console.log(err);
    });
    
  }

  openAGBDialog() {
    let dialogRef = this.dialog.open(AGBDialogComponent, { });
  }
}
