import { Component, OnInit } from '@angular/core';

import { fromEvent } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  isScreenSmall$;

  posts$;

  days = '?';

  favPosts: string[] = ['Spartan Race München 2019', 'Muddy Älbler', 'Hinweise fürs Training'];

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    // checks if the screen size is less than 728 Pixels
    const checkScreenSize = () => document.body.offsetWidth < 768;

    // create observable from window resize event throttled so every 500 ms it is fired
    const screenSizeChanged$ = fromEvent(window, 'resize').pipe(debounceTime(0), map(checkScreenSize));

    // Start off with the initival value use the isScreenSmall$ | async in the view to get both
    // the original value and the new value after a resize.
    this.isScreenSmall$ = screenSizeChanged$.pipe(startWith(checkScreenSize()));

    // fetch posts
    this.posts$ = this.postService.getPosts(4);

    let munichDate= new Date('2024-04-13').getTime();
    let now = new Date().getTime();
    // let diffTime = munichDate - now;

    this.days = Math.ceil(Math.abs(munichDate - now) / (1000 * 60 * 60 * 24)).toString(); 
  }
}