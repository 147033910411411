import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-teaser-box',
  templateUrl: './teaser-box.component.html',
  styleUrls: ['./teaser-box.component.scss']
})
export class TeaserBoxComponent implements OnInit {
  @Input() leftImage?: boolean;
  @Input() image: string;
  @Input() heading: string;
  @Input() linkUrl: string;
  @Input() linkLabel: string;
  @Input() text: string;

  constructor() { }
  ngOnInit(): void { }
}
