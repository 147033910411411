import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CookieConsentService } from 'src/app/services/cookie-consent.service';
import { MatStepper } from '@angular/material/stepper';
import { ValidateCheckbox } from './ValidateCheckbox';
import { SnackService } from 'src/shared/snack.service';
import { Router } from '@angular/router';

import firebase from 'firebase/app';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-survey-munich',
  templateUrl: './survey-munich.component.html',
  styleUrls: ['./survey-munich.component.scss']
})
export class SurveyMunichComponent implements OnInit, OnDestroy {
  cookieConsent: boolean;
  cookieConsent$: Subscription;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  secondFormSubmitted = false;
  thirdFormSubmitted = false;
  isActive = true;

  costInfo: {};

  public _reload = false;

  private reload() {
    setTimeout(() => this._reload = true, 1000);
  }


  constructor(private fb: FormBuilder,
    private db: AngularFirestore,
    private cookieService: CookieConsentService,
    private el: ElementRef,
    private snackService: SnackService,
    private router: Router,
    private mailService: MailService
  ) { }

  ngOnDestroy(): void {
    this.cookieConsent$.unsubscribe;
  }

  acceptCookies() {
    this.cookieService.cookieChange.next(true);
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  checkWizardStep(stepper: MatStepper, formGroup: string) {
    if (this[formGroup].valid) {
      this.goForward(stepper);
    } else {
      if (formGroup === "secondFormGroup") {
        this.secondFormSubmitted = true;
      }
      if (formGroup === "thirdFormGroup") {
        this.thirdFormSubmitted = true;
      }
      this.scrollToFirstInvalidControl();
    }
  }

  ngOnInit() {
    this.cookieConsent = this.cookieService.getCookieState();
    this.cookieConsent$ = this.cookieService.cookieChange.subscribe(hasAllowedCookies => {
      this.cookieConsent = hasAllowedCookies;
    });

    this.firstFormGroup = this.fb.group({
      agreement: ['2', [Validators.required, Validators.max(1)]]
    });

    this.secondFormGroup = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      residence: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', Validators.required],
      dateofbirth: ['', Validators.required],
      gender: ['', Validators.required]
    });

    this.thirdFormGroup = this.fb.group({
      clubmember: ['', Validators.required],
      viewer: ['', Validators.required],
      viewertext: [''],
      amountraces: [''],
      shelter: ['', Validators.required],
      shelterpartner: [''],
      arrival: ['', Validators.required],
      arrivaltext: [''],
      duration: ['', Validators.required],
      raceType: this.fb.group({
        raceopt1: [false],
        raceopt2: [false],
        raceopt3: [false],
        raceopt4: [false]
      }),
      weekendProgram: this.fb.group({
        opt1: [false],
        opt2: [false],
        opt3: [false],
        opt4: [false]
      }, { validators: [ValidateCheckbox.validate] })
    });

    this.fourthFormGroup = this.fb.group({
      misctext: [''],
      recaptchaReactive: [null, Validators.required]
    });

    this.thirdFormGroup.get('viewer').valueChanges.subscribe(value => {
      this.validateViewer(value)
    });

    this.thirdFormGroup.get('arrival').valueChanges.subscribe(value => {
      this.validateArrival(value)
    });
  } // end NgOnInit

  get f2() { return this.secondFormGroup.controls; }
  get f3() { return this.thirdFormGroup.controls; }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
    firstInvalidControl.focus();

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    })
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  validateViewer(viewerOption: string): void {
    const textControl = this.thirdFormGroup.get("viewertext")
    if (viewerOption === '3') {
      textControl.setValidators(Validators.required)
    } else {
      textControl.clearValidators();
    }
    textControl.updateValueAndValidity();
  }

  validateArrival(arrivalOption: string): void {
    const textControl = this.thirdFormGroup.get("arrivaltext")
    if (arrivalOption === '4') {
      textControl.setValidators(Validators.required)
    } else {
      textControl.clearValidators();
    }
    textControl.updateValueAndValidity();
  }

  getClubMemberValue() {
    return this.thirdFormGroup.value.clubmember
  }

  getViewerValue() {
    return this.thirdFormGroup.value.viewer
  }

  getShelterValue() {
    return this.thirdFormGroup.value.shelter
  }

  getArrivalValue() {
    return this.thirdFormGroup.value.arrival
  }

  getCurrentDate() {
    return new Date(firebase.firestore.Timestamp.now().seconds * 1000).toLocaleDateString()
  }

  /* Handle form errors in Angular 8 */
  public errorHandling = (form: string, control: string, error: string) => {
    return this[form].controls[control].hasError(error);
  }

  /** Berechnet Kosten fuer eine Person */
  getTotalCost() {
    let cost = 0;
    let returnString = [];
    let price = [];
    let priceObj = [];
    let priceNotKnown = false;

    let clubmemberValue = this.thirdFormGroup.get(['clubmember']).value;
    let viewerValue = this.thirdFormGroup.get(['viewer']).value; 

    let shelterValue = this.thirdFormGroup.get(['shelter']).value; // 3
    if (shelterValue == 3) {
      let durationValue = this.thirdFormGroup.get(['duration']).value;
      if(durationValue == '1') {
        returnString.push('Gemeinsame Unterkunft (Haus der Athleten) [35 € pro Person x 2 Nächte]');
        price.push(70);
        priceObj.push({
          label: 'Gemeinsame Unterkunft (Haus der Athleten) [35 € pro Person x 2 Nächte]',
          price: 70
        });
      } else {
        returnString.push('Gemeinsame Unterkunft (Haus der Athleten) [~35 € pro Person] ');
        price.push(35);
        priceObj.push({
          label: 'Gemeinsame Unterkunft (Haus der Athleten) [geschätzt 35 € pro Person] -> Preis könnte etwas ansteigen',
          price: 35
        });
      }
    }

    let arrivalValue = this.thirdFormGroup.get(['arrival']).value; // 3
    if (arrivalValue == 3) {
      returnString.push('Fahrgemeinschaft ab Albstadt-Tailfingen (Abfahrt Fr. 11:00 Uhr + Rückfahrt) [50 € pro Person]');
      price.push(50);
      priceObj.push({
        label: 'Fahrgemeinschaft ab Albstadt-Tailfingen (Abfahrt Fr. 11:00 Uhr + Rückfahrt) [50 € pro Person]',
        price: 50
      });
    }

    if (clubmemberValue == '1' && (viewerValue == '2' || viewerValue == '3')) {
      // Vereinsmitglied und Laueufer
      /*
      returnString.push('Vereinsmitglied [40 € pro Person] ');
      price.push(40);
      priceObj.push({
        label: 'Ticket Spartan Race (Vereinsmitglied) [40 € pro Person]',
        price: 40
      });
      */
    } else if(clubmemberValue == '2' && (viewerValue == '2' || viewerValue == '3')) {
      // Nicht-Vereinsmitglied und Laeufer
      /*
      returnString.push('Nicht-Vereinsmitglied [75 € pro Person] ');
      price.push(75);
      priceObj.push({
        label: 'Ticket Spartan Race (Nicht-Vereinsmitglied) [75 € pro Person]',
        price: 75
      });
      */
    } else {
      console.warn("Error: invalid clubMembervalue: " + clubmemberValue + "viewerValue: " + viewerValue);
    }

    var clubMemberAndSprinter = clubmemberValue == 1 && (viewerValue == '2' || viewerValue == '3');
    var nonClubMemberAndSprinter = clubmemberValue == 2 && (viewerValue == '2' || viewerValue == '3');
    let raceTypeValue = this.thirdFormGroup.get(['raceType']).value;

    if(clubMemberAndSprinter) {
      if(raceTypeValue.raceopt1) {
        var labelString = 'Sprint (5KM+, 20 Hindernisse) in der Open Kategorie [40 Euro pro Person] {Vereinsmitglied}';
        price.push(40);
        priceObj.push({
          label: labelString,
          price: 40
        });
      }
  
      if(raceTypeValue.raceopt2) {
        var labelString = 'Super (10KM+, 30 Hindernisse) in der Open Kategorie [65 Euro pro Person] {Vereinsmitglied}';
        price.push(65);
        priceObj.push({
          label: labelString,
          price: 65
        });
      }
  
      if(raceTypeValue.raceopt4) {
        var labelString = 'Super (10KM+, 30 Hindernisse) in der Age Group Kategorie [85 Euro pro Person] {Vereinsmitglied}';
        price.push(85);
        priceObj.push({
          label: labelString,
          price: 85
        });
      }
    } // Ende Vereinsmitglied und Läufer

    if(nonClubMemberAndSprinter) {
      if(raceTypeValue.raceopt1) {
        var labelString = 'Sprint (5KM+, 20 Hindernisse) in der Open Kategorie [70 Euro pro Person] {Nicht-Vereinsmitglied}';
        price.push(70);
        priceObj.push({
          label: labelString,
          price: 70
        });
      }
  
      if(raceTypeValue.raceopt2) {
        var labelString = 'Super (10KM+, 30 Hindernisse) in der Open Kategorie [95 Euro pro Person] {Nicht-Vereinsmitglied}';
        price.push(95);
        priceObj.push({
          label: labelString,
          price: 95
        });
      }
  
      if(raceTypeValue.raceopt4) {
        var labelString = 'Super (10KM+, 30 Hindernisse) in der Age Group Kategorie [115 Euro pro Person] {Nicht-Vereinsmitglied}';
        price.push(115);
        priceObj.push({
          label: labelString,
          price: 115
        });
      }
    } // Ende Kein Vereinsmitglied und Läufer

    let weekendProgramValue = this.thirdFormGroup.get(['weekendProgram']).value;

    if (weekendProgramValue.opt1) {
      returnString.push('gemeinsames Frühstück Samstags (im Haus der Athleten als Selbstversorger) [4 Euro pro Person]');
      price.push(4);
      priceObj.push({
        label: 'gemeinsames Frühstück Samstags (im Haus der Athleten als Selbstversorger) [4 Euro pro Person]',
        price: 4
      });
    }

    if (weekendProgramValue.opt2) {
      priceNotKnown = true;
    }

    if (weekendProgramValue.opt3) {
      returnString.push('gemeinsames Frühstück Sonntags (im Haus der Athleten als Selbstversorger) [4 € pro Person]');
      price.push(4);
      priceObj.push({
        label: 'gemeinsames Frühstück Sonntags (im Haus der Athleten als Selbstversorger) [4 € pro Person]',
        price: 4
      });
    }

    cost = price
      .filter((priceValue) => typeof priceValue === 'number')
      .reduce((acc, curr) => acc + curr, 0);

    this.reload();

    const returnObj = {
      price: price,
      returnString: returnString,
      cost: cost,
      priceObj: priceObj,
      priceNotKnown: priceNotKnown
    }

    this.costInfo = returnObj;
  }

  onSubmit() {
    const secondFormObj = Object.assign({}, this.secondFormGroup.value);
    var gender = "unbekannt";
    if (secondFormObj["gender"] === "1") {
      gender = "male"
    }
    if (secondFormObj["gender"] === "2") {
      gender = "female"
    }
    secondFormObj["gender"] = gender;

    const thirdFormObj = Object.assign({}, this.thirdFormGroup.value);

    var clubmember = "Nein"; 
    if (thirdFormObj["clubmember"] === "1") {
      clubmember = "vereinsmitglied"
    }
    if (thirdFormObj["clubmember"] === "2") {
      clubmember = "nicht-vereinsmitglied"
    }
    thirdFormObj["clubmember"] = clubmember;

    var viewer = "self_viewer"; // no_viewer // bring_viewer
    if (thirdFormObj["viewer"] === "1") {
      viewer = "self_viewer"
    }
    if (thirdFormObj["viewer"] === "2") {
      viewer = "no_viewer"
    }
    if (thirdFormObj["viewer"] === "3") {
      viewer = "bring_viewer"
    }
    thirdFormObj["viewer"] = viewer;

    var shelter = "";
    if (thirdFormObj["shelter"] === "1") {
      shelter = "privat"
    }
    if (thirdFormObj["shelter"] === "2") {
      shelter = "self_organized"
    }
    if (thirdFormObj["shelter"] === "3") {
      shelter = "community_shelter"
    }
    thirdFormObj["shelter"] = shelter;

    var arrival = "";
    if (thirdFormObj["arrival"] === "1") {
      arrival = "own_vehicle"
    }
    if (thirdFormObj["arrival"] === "2") {
      arrival = "train_bus_plain_feet"
    }
    if (thirdFormObj["arrival"] === "3") {
      arrival = "car_pool"
    }
    if (thirdFormObj["arrival"] === "4") {
      arrival = "misc"
    }
    thirdFormObj["arrival"] = arrival;

    var duration = "";
    if (thirdFormObj["duration"] === "1") {
      duration = "FR-SO"
    }
    if (thirdFormObj["duration"] === "2") {
      duration = "SA-SO"
    }
    if (thirdFormObj["duration"] === "3") {
      duration = "FR-SA"
    }
    thirdFormObj["duration"] = duration;

    const comment = this.fourthFormGroup.get('misctext').value;

    const resultObj = { ...secondFormObj, ...thirdFormObj, comment: comment, created: this.getCurrentDate() };

    this.db.collection("sprint2024").add(resultObj).then((r) => {
      this.mailService.sendFormMail(JSON.stringify(resultObj));
      this.snackService.showMessage("Formular erfolgreich übermittelt!");
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 3500);
    }).catch((err) => {
      this.snackService.showError('Entschuldige, da ist etwas ist schiefgelaufen :( Fehler: ' + err);
    });

  }
}
