import { Component, OnInit } from '@angular/core';
import { ProfileCard } from 'src/shared/ProfileCard';

@Component({
  selector: 'app-verein',
  templateUrl: './verein.component.html',
  styleUrls: ['./verein.component.scss']
})
export class VereinComponent implements OnInit {

  profiles: ProfileCard[] = [
    {
      name: 'Niko Oestreicher',
      position: `Abteilungsleiter OCR & Gründungsmitglied`,
      avatarImg: 'assets/images/static/about-us/niko.jpg',
      cardBGImage: 'assets/images/static/about-us/card-bg-niko.jpg',
      email: 'oestreicher@dlombasegglvodralb.de',
      facebook: 'https://www.facebook.com/niko.oestreicher',
      instagram: 'https://www.instagram.com/nikooestreicher/',
    },
    {
      name: 'Maren Oestreicher',
      position: `stellvertretende Abteilungsleiterin OCR`,
      avatarImg: 'assets/images/static/about-us/maren.jpg',
      cardBGImage: 'assets/images/static/about-us/card-bg-maren.jpg',
      email: 'wolter@dlombasegglvodralb.de',
      facebook: 'https://www.facebook.com/maren.wltr',
      instagram: 'https://www.instagram.com/marenwo_/',
    },
    {
      name: 'Simon Wagegg',
      position: `Berater OCR & Gründungsmitglied`,
      avatarImg: 'assets/images/static/about-us/simon.jpg',
      cardBGImage: 'assets/images/static/about-us/card-bg-simon.jpg',
      email: 'wagegg@dlombasegglvodralb.de',
      facebook: 'https://www.facebook.com/sim.wag',
      instagram: 'https://www.instagram.com/sim.wag93/',
    },
    {
      name: 'Kevin Olomu',
      position: `Verantwortlicher IT / Website`,
      avatarImg: 'assets/images/static/about-us/kevin.jpg',
      cardBGImage: 'assets/images/static/about-us/card-bg-kevin.jpg',
      email: 'olomu@dlombasegglvodralb.de',
      facebook: 'https://www.facebook.com/people/Kevin-Olomu/100013412140706',
      instagram: 'https://www.instagram.com/kevinohnono/',
    },
    {
      name: 'Tobias Erich',
      position: `Verantwortlicher IT / Website`,
      avatarImg: 'assets/images/static/about-us/tobias.jpg',
      cardBGImage: 'assets/images/static/about-us/card-bg-tobias.jpg',
      facebook: 'https://www.facebook.com/tobias.erich',
    }

  ];

  constructor() { }
  ngOnInit() { }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


}
