import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SnackService } from 'src/shared/snack.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private snack: SnackService) { }

  async canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Promise<boolean> {
    const user = this.authService.getCurrentUser();
    const isLoggedIn = !!user;  // double bang (object to bool value -> reverse the result for original bool
    
    if(!isLoggedIn) {
      this.snack.authError();
    }
    
    return isLoggedIn;
  }

}
