import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { ContactService } from "src/app/services/contact.service";
import { DatenschutzDialogComponent } from "src/app/components/datenschutz-dialog/datenschutz-dialog.component";
import { CookieConsentService } from 'src/app/services/cookie-consent.service';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, OnDestroy {
  windowRef: any;
  serverMessage: string;
  loading = false;
  contactForm: FormGroup;
  isSuccesssullSubmitted = false;

  cookieConsent: boolean;
  
  cookieConsent$: Subscription;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private contactService: ContactService,
    private cookieService: CookieConsentService    
  ) { }

  ngOnDestroy(): void { 
    this.cookieConsent$.unsubscribe;
  }

  acceptCookies() {
    this.cookieService.cookieChange.next(true);
  }

  ngOnInit() {
    this.cookieConsent = this.cookieService.getCookieState();

    this.cookieConsent$ = this.cookieService.cookieChange.subscribe( hasAllowedCookies => {
      this.cookieConsent = hasAllowedCookies;
    });

    this.contactForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      betreff: ["", [Validators.required, Validators.minLength(1)]],
      content: ["", [Validators.required, Validators.minLength(1)]],
      captcha: ["", Validators.required]
    });
  }

  onSubmit() {
    this.loading = true;
    this.contactService
      .sendMail({
        subject: this.betreff.value,
        message: this.content.value,
        email: this.email.value,
      })
      .then((val) => {
        this.loading = false;
        this.isSuccesssullSubmitted = true;
      })
      .catch((err) => {
        this.serverMessage = err;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  openDatenschutzDialog() {
    this.dialog.open(DatenschutzDialogComponent, {});
  }

  get email() {
    return this.contactForm.get("email");
  }
  get betreff() {
    return this.contactForm.get("betreff");
  }
  get content() {
    return this.contactForm.get("content");
  }
}
