import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: 'dlombasegglvodralb.de'// it is recommended to set your domain, for cookies to work properly
    },
    palette: {
      popup: {
        background: '#FFF'
      },
      button: {
        background: '#f1d600'
      }
    },
    theme: 'classic',
    type: 'opt-in',
    position: "bottom-right",
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}} 
        <a aria-label="erfahre mehr über unsere Datenschutzerklärung" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> gelesen haben und einverstanden sind.
      </span>
      `,
    },

    content:{
      message: 'Bitte bestätigen Sie, dass Sie unsere ',
  
      privacyPolicyLink: 'Datenschutzerklärung',
      privacyPolicyHref: '/datenschutz',

      allow: "Akzeptieren",
      deny: "Ablehnen",

      policy: "Cookie & Datenschutz Richtlinien"
    }
  };