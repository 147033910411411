import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/* This guard will redirect to profile page so when member is signed up via google they must now add additional information 
to view advanced pages...  */
@Injectable({
  providedIn: 'root'
})
export class MemberInfoGuard implements CanActivate {
  constructor(private router: Router,
    private authService: AuthService) { }

  // bei diesem CanActivate gehen wir bereits davon aus, dass der user sich erfolgreich authentifiziert hat.
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const user = this.authService.getCurrentUser();

      console.log("MemberInfoGuard: getProfileCompletion()" + this.authService.getProfileCompletion())
      console.log(user);
      


      if (user) {
        this.authService.getProfileCompletion() ? resolve(true) : this.router.navigate(['edit-profile']);
      } else {
        console.log('no user....');
      }

    });

  }
}
