import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  constructor(private snackBar: MatSnackBar, private router: Router) { }

  showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
      panelClass: ['green-snackbar']
    });
  }

  showError(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  authError() {
    this.snackBar.open('You must be logged in!', 'OK', {
      duration: 5000
    });
    return this.snackBar._openedSnackBarRef
      .onAction()
      .pipe(
        tap(_ => this.router.navigate(['/signin']))
      ).subscribe();
  }
}
