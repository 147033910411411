import { Component, OnInit, Input } from '@angular/core';
import { faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import { ProfileCard } from 'src/shared/ProfileCard';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
  @Input() profile: ProfileCard;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faInstagram = faInstagram;
  faFacebookSquare = faFacebookSquare;

  constructor() { }
  ngOnInit(): void {}

}
