import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-datenschutz-dialog',
  templateUrl: './datenschutz-dialog.component.html'
})
export class DatenschutzDialogComponent { 
  constructor(public dialogRef: MatDialogRef<DatenschutzDialogComponent>) { }
  onOKClick(): void {
    this.dialogRef.close();
  }
}
