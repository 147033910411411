import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService implements OnDestroy {
  cookieChange = new Subject<boolean>();
  
  private cookieState = false;

  private cookieChangeSub$: Subscription;
  
  constructor(private ccService: NgcCookieConsentService) {
    this.cookieChangeSub$ = this.cookieChange.subscribe( cookieConsentGiven => {
      this.cookieState = cookieConsentGiven;

      if(cookieConsentGiven) {
        // remove the cookie consent window if it is still active
        this.ccService.close(true);

        const recaptchaScript = document.createElement('script');
        recaptchaScript.src = "https://www.google.com/recaptcha/api.js?render=explicit";
        recaptchaScript.id = "recaptcha-script"
        document.head.appendChild(recaptchaScript);
      } else  {
        const scriptEl = document.getElementById("recaptcha-script");
        if(scriptEl) {
          scriptEl.remove();
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.cookieChangeSub$.unsubscribe();
  }

  public getCookieState(): boolean {
    return this.cookieState;
  }
}
