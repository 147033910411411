import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Profile } from 'src/shared/Profile';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  userSub$: Subscription;
  user: Profile;

  constructor(public profileService: ProfileService) { }

  async ngOnInit() {
    this.userSub$ = (await this.profileService.getUser()).subscribe((user: Profile) => this.user = user);
  }

  ngOnDestroy(): void {
    this.userSub$.unsubscribe();
  }
}
