import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, AuthenticateState } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  public authState: AuthenticateState;
  public AuthStateType = AuthenticateState;
  @Output() sidenavClose = new EventEmitter();

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.authChange.subscribe( authStatus => {
      this.authState = authStatus;
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  async signOut() {
    await this.authService.signOut();
    this.navigateTo('');
  }

}
