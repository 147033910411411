import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  sendFormMail(formContent: any) {
      const url = "https://europe-west1-lombasegglvodralb.cloudfunctions.net/sendmail";
      return this.http.post(url, {content: formContent}).toPromise()
  }
}
