import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Profile } from 'src/shared/Profile';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) { }

  async updateProfile(profile: Profile): Promise<void> {
    console.log('updateProfile() called!');
    const userId = (await this.afAuth.currentUser).uid;
    const userDoc = await this.db
      .collection<Profile>('users')
      .doc(userId)
      .get().toPromise();

      return userDoc.ref.set(profile);
  }

  async getUser() {
    console.log('getUser() called!');
    const userId = (await this.afAuth.currentUser).uid;
    return this.db.collection<Profile>('users').doc(userId).valueChanges();
  }
}
