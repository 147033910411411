import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PostComponent } from './pages/post/post.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { VereinComponent } from './pages/verein/verein.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { AuthGuard } from './user/auth.guard';
import { MemberComponent } from './member/member.component';
import { MemberInfoGuard } from './user/member-info.guard';
import { EditProfileComponent } from './user/edit-profile/edit-profile.component';
import { AllnewsComponent } from './pages/allnews/allnews.component';
import { NutzungsbedingungenComponent } from './pages/nutzungsbedingungen/nutzungsbedingungen.component';
import { SurveyMunichComponent } from './components/survey-munich/survey-munich.component';

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  {
    path: 'signin',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'member',
    component: MemberComponent,
    canActivate: [AuthGuard, MemberInfoGuard]
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard]
  },

  // standard pages
  { path: 'verein', component: VereinComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'news', component: AllnewsComponent },
  { path: 'post/:id', component: PostComponent },
  { path: 'nutzungsbedingungen', component: NutzungsbedingungenComponent },
  { path: 'sprint-2024', component: SurveyMunichComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
