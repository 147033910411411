import { Injectable } from '@angular/core';
import { Subject, of, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Profile } from 'src/shared/Profile';
import { switchMap, take, filter } from 'rxjs/operators';

export enum AuthenticateState {
  Authenticated,
  Admin,
  NotAuthenticated
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange = new Subject<AuthenticateState>();
  private isAuthenticated = false;
  private isAdministrator = false;
  private hasProfileComplete = false;

  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth) { }

  initAuthListener(): Subscription[] {
    const authStateSub = this.afAuth.authState.pipe(
      // check if user is authenticated
      filter(user => { 
        if(user == undefined) {
          this.authChange.next(AuthenticateState.NotAuthenticated);
          return false;
        } else {
            this.authChange.next(AuthenticateState.Authenticated);
            return true;
          }
      }),
      // if the user is authenticated search in the firestore collection for its document
      switchMap(user => this.db.collection('users').doc(user.uid).valueChanges().pipe(take(1)))
      ).subscribe((profile: Profile) => {
        // we have
        this.hasProfileComplete = true;
        if (profile.role === 'ADMIN') {
          this.authChange.next(AuthenticateState.Admin);
        }
    });

    const authChangeSub = this.authChange.subscribe( authState => {
      switch(authState) {
        case AuthenticateState.Admin: {
          this.isAdministrator = true;
          this.isAuthenticated = true;
          break;
        }
        case AuthenticateState.Authenticated: {
          this.isAdministrator = false;
          this.isAuthenticated = true;
          break;
        }
        case AuthenticateState.NotAuthenticated: {
          this.isAdministrator = false;
          this.isAuthenticated = false;
          this.hasProfileComplete = false;
          break;
        }
      }
    });

    return [authStateSub, authChangeSub];
  }

  signOut() {
    this.isAuthenticated = this.hasProfileComplete = this.isAdministrator = false;
    return this.afAuth.signOut();
  }

  isAuth() {
    return this.isAuthenticated;
  }

  isAdmin() {
    return this.isAdministrator;
  }

  getCurrentUser() {
    return this.afAuth.currentUser;
  }

  getProfileCompletion() {
    return this.hasProfileComplete;
  }

}
