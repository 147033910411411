import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

import { Post } from 'src/shared/Post';
declare var Superclamp: any;

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit, AfterViewInit {
  @Input() post: Post;
  @Input() inNewsList: boolean = false;

  maxHeightContent = -1;
  
  @ViewChild('truncate', { static: false }) truncateRef: ElementRef;
  @ViewChild('newscard', { static: false }) newsCardRef: ElementRef;
  @ViewChild('cardImg',  { static: false }) cardImg: ElementRef;
  @ViewChild('cardTitle',{ static: false }) cardTitle: ElementRef;
  @ViewChild('moreLink',{ static: false }) moreLink: ElementRef;
  
  constructor() { }
  
  determineRemainingSize(): void {
    const cardHeight = this.newsCardRef.nativeElement.clientHeight;
    const imgHeight = this.cardImg.nativeElement.clientHeight;
    const titleHeight = this.cardTitle.nativeElement.clientHeight;
    const moreLinkHeight = this.moreLink.nativeElement.clientHeight;
    const magicPadding = 3*16;
    const remainingHeight = cardHeight - (magicPadding + imgHeight + titleHeight + moreLinkHeight);
    this.maxHeightContent = remainingHeight;
  }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      this.determineRemainingSize();
      Superclamp.reclampAll();
    });
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.determineRemainingSize();
      Superclamp.register([this.truncateRef.nativeElement]);
      Superclamp.reclampAll();  
    }, 0);

    // for some weird reason the third card needs to be reclamped a second time?!
    setTimeout( () => {
      Superclamp.reclampAll();  
    }, 100);
  }

}
