import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  submitUrlContactForm = "https://europe-west1-lombasegglvodralb.cloudfunctions.net/submit ";

  constructor(private http: HttpClient) { }

  sendMail(mailObj: {subject: string, message: string, email: string}) {
    return this.http.post(this.submitUrlContactForm, mailObj).toPromise()
  }
}
