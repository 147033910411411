import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, AuthenticateState } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy{
  authSubscription: Subscription;
  public authState: AuthenticateState;
  public AuthStateType = AuthenticateState;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.authChange.subscribe( authStatus => {
      this.authState = authStatus;
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  async signOut() {
    await this.authService.signOut();
    this.navigateTo('');
  }

}
