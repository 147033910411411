import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { Post } from 'src/shared/Post';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  
  public post$: Observable<Post>;

  constructor(private postService: PostService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.post$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.postService.getApprovedPost(params.get('id'))),
      map( (p: Post[]) => { 
        // change the original image to smaller image thumbnail:
        const currentPost = p[0];
        const imgRegex = /pimages%2F/g;

        currentPost.content = currentPost.content
          .replace(imgRegex, "pimages%2Frs%2F")
          .replace(/[.]jpg/g, "_1060x1060.jpg")
          .replace(/[.]jpeg/g, "_1060x1060.jpeg");
  
        return p[0];
      })
    );
  }
}
